






import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import gsap from 'gsap'
import Draggable from 'gsap/Draggable'
import InertiaPlugin from 'gsap/InertiaPlugin'
import { getBreakpointValue } from '@/utils/media'
import eventBus from '@/utils/event-bus'
import EventType from '@/constants/event-type'
import { Getter, State } from 'vuex-class'

gsap.registerPlugin(Draggable, InertiaPlugin)

@Component
export default class ScrollerHorizontal extends Vue {
    @Prop({ default: 0 }) indexY!: number
    @Prop({ default: 0 }) y!: number
    @Prop() endDragY!: Function
    @Prop({ default: 0 }) parentCurrentSlideIndex!: number
    @Prop({ default: 0 }) numberSlides!: number

    @State transitionIsVisible!: boolean

    @Getter navigationIsAllowed!: boolean

    currentSlideIndex = this.parentCurrentSlideIndex
    x = 0
    windowWidth = window.innerWidth
    windowHeight = window.innerHeight

    drag!: Draggable
    xSetter!: Function

    get isSlideshow() {
        return this.windowWidth >= getBreakpointValue('lg')
    }

    get bounds(): Draggable.BoundsMinMax {
        return {
            minX: (this.numberSlides - 1) * -this.windowWidth,
            maxX: 0,
            minY: 0,
            maxY: 0
        }
    }

    getPosition() {
        return this.currentSlideIndex * this.windowWidth * -1
    }

    mounted() {
        this.x = this.getPosition()

        this.initDrag()

        window.addEventListener('keyup', this.onKeyUp)

        eventBus.$on(EventType.RESIZE, this.onResize)

        if (this.x !== 0) {
            this.updateDrag()
        }
    }

    beforeDestroy() {
        window.removeEventListener('keyup', this.onKeyUp)

        eventBus.$off(EventType.RESIZE, this.onResize)

        this.disposeDrag()
    }

    initDrag(): void {
        const proxy = document.createElement('div')
        const options: GSAPDraggableVars = {
            type: this.isSlideshow ? 'x,y' : 'x',
            lockAxis: true,
            bounds: this.bounds,
            trigger: this.$el,
            inertia: true,
            throwResistance: 3000,
            allowContextMenu: true,
            dragResistance: 0.4,
            edgeResistance: 0.8,
            zIndexBoost: false,
            onDragStart: this.onDragStart,
            onDrag: this.onDrag,
            onDragEnd: this.onDragEnd,
            onThrowUpdate: this.onThrowUpdate,
            onThrowComplete: this.onThrowComplete,
            snap: this.snapDrag
        }

        this.xSetter = gsap.quickSetter(proxy, 'x', 'px')

        this.drag = Draggable.create(proxy, options)[0]

        if (!this.navigationIsAllowed) this.drag.enabled(false)
    }

    disposeDrag() {
        if (this.drag) this.drag.kill()
    }

    snapDrag(value: number): number {
        return Math.round(value / this.windowWidth) * this.windowWidth
    }

    updatePosition() {
        if (this.drag.tween) this.drag.tween.pause()

        const tween = gsap.to(this, {
            x: this.getPosition(),
            duration: 0.9,
            ease: 'power3.inOut',
            onUpdate: this.updateDrag,
            onComplete: this.tweenComplete
        })

        // maybe the horizontal scroller is behind the transition screen
        // (e.g. the collection carousel)
        if (this.transitionIsVisible) tween.progress(1)
    }

    isVisible() {
        if (this.isSlideshow) {
            const baseY = this.indexY * this.windowHeight

            return baseY + this.y + this.windowHeight > 0 && baseY + this.y < this.windowHeight
        } else {
            const rect = this.$el.getBoundingClientRect()

            return rect.bottom > 0 && rect.top < this.windowHeight
        }
    }

    updateDrag() {
        this.xSetter(this.x)
        this.drag.update()
    }

    gotoNextSlide() {
        const index = this.currentSlideIndex + 1

        if (index > this.numberSlides - 1) return

        this.gotoSlide(index)
    }

    gotoPreviousSlide() {
        const index = this.currentSlideIndex - 1

        if (index < 0) return

        this.gotoSlide(index)
    }

    gotoSlide(index: number) {
        this.currentSlideIndex = index
    }

    tweenComplete() {
        this.$emit('tween-complete')
    }

    onKeyUp(event: KeyboardEvent) {
        if (!this.isVisible() || !this.navigationIsAllowed) return

        switch (event.key) {
            case 'ArrowRight':
                this.gotoNextSlide()
                break

            case 'ArrowLeft':
                this.gotoPreviousSlide()
                break
        }
    }

    onResize() {
        gsap.killTweensOf(this, { x: true })

        this.windowWidth = window.innerWidth
        this.windowHeight = window.innerHeight

        this.x = this.getPosition()
    }

    onDragStart(event: PointerEvent) {
        const direction = this.drag.getDirection('start')

        if (direction === 'up' || direction === 'down') this.drag.endDrag(event)
        else this.endDragY(event)
    }

    onDrag() {
        this.x = this.drag.x
    }

    onThrowUpdate() {
        this.x = this.drag.x
    }

    onThrowComplete() {
        this.tweenComplete()
    }

    onDragEnd() {
        const index = Math.floor(this.drag.endX / -this.windowWidth)
        const ratio = (this.drag.endX - this.drag.x) / this.windowWidth

        if (index === this.currentSlideIndex && Math.abs(ratio) > 0.15) {
            this.$nextTick(() => {
                if (ratio < 0) this.gotoPreviousSlide()
                else this.gotoNextSlide()
            })
        } else {
            this.currentSlideIndex = index
        }
    }

    getDirection() {
        return this.drag.getDirection('start')
    }

    @Watch('currentSlideIndex')
    onCurrentSlideIndexChange() {
        const index = Math.floor(this.drag.endX / -this.windowWidth)

        this.$emit('current-slide-index-change', { currentSlideIndex: this.currentSlideIndex })

        if (this.drag.tween && this.drag.tween.isActive() && index === this.currentSlideIndex) return

        this.updatePosition()
    }

    @Watch('parentCurrentSlideIndex')
    onParentCurrentSlideIndexChange() {
        this.currentSlideIndex = this.parentCurrentSlideIndex
    }

    @Watch('navigationIsAllowed')
    onNavigationIsAllowedChange() {
        if (this.drag) this.drag.enabled(this.navigationIsAllowed)
    }

    @Watch('bounds')
    onBoundsChange() {
        this.disposeDrag()
        this.initDrag()
        this.updateDrag()
        this.updatePosition()
    }

    @Watch('numberSlides')
    onNumberSlidesChange() {
        this.currentSlideIndex = Math.min(this.currentSlideIndex, this.numberSlides - 1)
        // if (this.drag) {
        //     this.$nextTick(() => {
        //         this.drag.applyBounds(this.bounds)
        //         this.drag.update()
        //     })
        // }
    }
}
